export enum Status {
	"Undone", "Done", "Needs", "Urgent", "Recurring", "Pending", "Hold", "Cancelled"
};

export type StatusColor = "default" | "success" | "primary" | "secondary" | "warning" | "danger";


export function statusToColor(status: Status):
	"default" | "success" | "primary" | "secondary" | "warning" | "danger" {
	switch (status) {
		case Status.Undone: return "warning";
		case Status.Done: return "success";
		case Status.Hold: return "warning";
		case Status.Needs: return "secondary";
		case Status.Urgent: return "danger";
		case Status.Pending: return "default";
		case Status.Recurring: return "danger";
		case Status.Cancelled: return "default";
	}
}

export interface Task {
	text: string;
	status: Status;
}


