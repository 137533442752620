import { Input, Select, SelectItem, NextUIProvider } from '@nextui-org/react';
import { ThemeProvider } from 'next-themes';
import { ChangeEventHandler, useState } from 'react';
import { lambdaRequest } from './api/lambda';
import { Task, Status, StatusColor, statusToColor } from './models/shared';

function App() {
	const [todo, setTodo] = useState("");
	const [success, setSuccess] = useState(false);
	const [failure, setFailure] = useState(false);
	const [status, setStatus] = useState(new Set(["Undone"]));

	function changeStatus(e: ChangeEventHandler<HTMLSelectElement>) {
		//@ts-ignore
		setStatus(new Set<Status>([e.target.value]));
	}

	function getStatus(): Status {
		//@ts-ignore
		return status.keys().next().value !== ""
			? status.keys().next().value
			: Status.Undone.toString();

	}

	function getStatusColor(): StatusColor {
		//@ts-ignore
		return status.keys().next().value !== ""
			//@ts-ignore
			? statusToColor(Status[status.keys().next().value])
			: "default";
	}

	function getTask(): Task {

		return {
			text: todo,
			status: getStatus(),
		}
	}
	return (
		<NextUIProvider>
			<ThemeProvider attribute="class" defaultTheme="dark">
				<div style={{ display: "flex", height: "100vh" }}>
					<div style={{ margin: "auto", display: "flex", gap: ".5rem", }}>
						<Input
							placeholder='Todo'
							value={todo}
							onChange={(e) => setTodo(e.target.value)}
							color={success ? 'success' : failure ? 'danger' : 'default'}
							onKeyDown={(e) => {
								setSuccess(false);
								setFailure(false);
								if (e.key === 'Enter') {

									lambdaRequest({
										apiPath: "/add",
										opts: {
											method: "POST",
											body: JSON.stringify(getTask())
										}
									})
										.then((res) =>
											res.status === 200 ? setSuccess(true) : setFailure(true)
										)
										.then(() => {
											setTodo("");
											setStatus(new Set(["Undone"]));
										});
								}
							}}
							style={{ width: "50vw" }}
						/>
						<Select
							label="Status"
							labelPlacement="outside"
							color={getStatusColor()}
							defaultSelectedKeys={new Set(["Undone"])}
							//@ts-ignore
							onChange={changeStatus}
							style={{ minWidth: "7rem" }}
						>
							{
								Object.keys(Status)
									.filter((status) => isNaN(Number(status)))
									.map((status, i) =>
										<SelectItem
											key={status} value={status}
										>
											{status}
										</SelectItem>
									)
							}
						</Select>
					</div>
				</div>
			</ThemeProvider>
		</NextUIProvider >
	);
}

export default App;
