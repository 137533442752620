import { NextUIProvider } from "@nextui-org/react";
import { ScrollRestoration } from "react-router-dom";
import App from "../App";

export default function Root() {
	return (
		<NextUIProvider>
			<ScrollRestoration />
			<App />
		</NextUIProvider>
	)
}
