export async function lambdaRequest(
	{
		apiPath,
		opts,
		queryParams
	}:
		{
			apiPath: string,
			opts?: RequestInit,
			queryParams?: Array<[String, String]>,
		}
) {
	const isDev = process.env.NODE_ENV === "development";
	const baseUrl = isDev
		? "http://localhost:9000/lambda-url"
		: "https://ldia4kq77mkass5mx4gzd5iwea0tqtfb.lambda-url.us-east-1.on.aws";
	let url = `
		${baseUrl}
		${apiPath}
		${isDev ? "/" : ""}
		${queryParams ? "?" + queryParams.map(([k, v]) => k + "=" + v).join("&") : ""}`

	return fetch(url, {
		...(opts ? opts : {}),
		headers: {
			"Content-Type": "application/json",
		},
		mode: "cors",
	});
}
